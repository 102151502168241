import { Controller } from "stimulus"
import * as monaco from 'monaco-editor'

export default class extends Controller {
    static targets = ["container", "input"]
  connect() {
    this.editor = monaco.editor.create(this.containerTarget, {
        value: this.inputTarget.value,
        language: 'liquid',
        automaticLayout: true
      });
  }

  submit(event) {
      this.inputTarget.value = this.editor.getValue()
      return true
  }
}
