require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("select2")
require("moment")
require("metismenu")
require("toastr")

import toastr from 'toastr'
window.toastr = toastr

import $ from 'jquery';
global.$ = jQuery;

$( document ).on('turbolinks:load', function() {
  $('.side-nav').metisMenu();
  $('#checkAll').change(function() {
     if($(this).is(":checked")) {
        $('.order[type="checkbox"]').prop("checked", true)
        return;
     }
     $('.order[type="checkbox"]').prop("checked", false)
  });
})

import "controllers"
